{
  "notFound": "404 - Not Found",
  "traducteur": "Переклад Oleksandr Agarkov",
  "contact-us": "",
  "head": {
    "meta": {
      "description": "Ce test de pureté consiste en quelques questions (ne les comptez pas) portant pour la plupart, sur les thèmes de l'alcool, du sexe, et de la drogue...",
      "title": "Purity Test"
    }
  },
  "menu": {
    "test-purete": "Purity Test",
    "random-shitpost": "random-shitpost.com"
  },
  "home-page": {
    "title": "Purity Test",
    "subtitle": "Даний тест складається з питань на тему алкоголю, сексу і наркотиків.",
    "intro-text": {
      "line-1": "Деякі питання можуть бути образливими для Вас.",
      "line-2": "Якщо це так, то не продовжуйте далі і закрийте вкладку з цим сайтом...",
      "line-3": ""
    },
    "beware": "Будь ласка, не натискайте кнопку назад під час тесту!(Тест анулюється і прийдеться проходити заново)... Удачі !!",
    "ad-block": {
      "title": "⬇️Découvre notre nouveau site inutile⬇️"
    },
    "begin-griffor-btn": "👉 Purity test класичний",
    "begin-boudah-btn": "Альтернативна версія",
    "continue-btn": "відновити",
    "ad-warning": {
      "warning": "☝️ Attention, si une publicité s'ouvre, faites 'retour' ☝️",
      "our-test": "(notre test est et sera",
      "always": "toujours",
      "free": "gratuit) 😇"
    },
    "already": "Вже ",
    "tests-made": " пройдених тестів! 🎉"
  },
  "possibleAnswers": {
    "oui": "так",
    "non": "ні"
  },
  "questions": [
    {
      "id": "riMalheur",
      "question": "Ви сміялися з чужого горя ?"
    },
    {
      "id": "riMental",
      "question": "Ви сміялися над кимось із психічними чи фізичними вадами/розладами ?"
    },
    {
      "id": "poserLapin",
      "question": "Чи не приходили Ви вже на зустріч, не попереджаючи про це ?"
    },
    {
      "id": "foisArrete",
      "question": "Скільки разів Ви були заарештовані ?"
    },
    {
      "id": "gouterAlcool",
      "question": "Ви пробували алкоголь ?"
    },
    {
      "id": "dejaIvre",
      "question": "Чи бували Ви п'яним/бухим ?"
    },
    {
      "id": "ivre48h",
      "question": "Ви були п'яні більше 48 годин ?"
    },
    {
      "id": "tomberIvre",
      "question": "Ви вже падали через те, що були дуже п’яними ?"
    },
    {
      "id": "pisserDessus",
      "question": "Ви коли-небудь мочилися на собі, коли були п’яними ?"
    },
    {
      "id": "participerBoire",
      "question": "Ви коли-небудь брали участь в іграх на випивку ?"
    },
    {
      "id": "buVomir",
      "question": "Ви вже напивались так, що ригали ?"
    },
    {
      "id": "vomiQuelquun",
      "question": "Чи бувало таке, що Ви ригали на себе або на когось ?"
    },
    {
      "id": "virerNuit",
      "question": "Вас вже виганяли з бару чи клубу не по власному бажанню ?"
    },
    {
      "id": "barathon",
      "question": "Ви вже брали участь у барафоні ? (Випити у всіх барах міста або вулиці) ?"
    },
    {
      "id": "alcoolDejeuner",
      "question": "Чи пили Ви алкоголь на сніданок (похмелялись) ?"
    },
    {
      "id": "alcool3fois",
      "question": "Чи вживаєте Ви алкоголь як мінімум 3 рази на тиждень ?"
    },
    {
      "id": "jourPlus",
      "question": "В додаток до тих 3 днів, скільки ще днів зверху Ви п'єте ?"
    },
    {
      "id": "dormiToilette",
      "question": "Чи спали Ви вже в туалеті ?"
    },
    {
      "id": "volontaireToilette",
      "question": "...Ви самі захотіли спати в цьому місці ?"
    },
    {
      "id": "evanouiBar",
      "question": "Чи було таке, що Ви вже засинали або вирубувались в барі ?"
    },
    {
      "id": "avalerVomi",
      "question": "Чи ковтали Ви свою або чужу блювоту ?"
    },
    {
      "id": "vomiLieuPublic",
      "question": "Чи ригали Ви в публічному місці ?"
    },
    {
      "id": "urinerPasPrevu",
      "question": "Чи сцяли Ви в місці не призначеному для цього ?"
    },
    {
      "id": "essayerDrogue",
      "question": "Ви вже вживали наркотики ?"
    },
    {
      "id": "drogueEssaye",
      "question": "Скільки видів наркотиків Ви вже вживали ?"
    },
    {
      "id": "defonce48h",
      "question": "Чи були Ви п'або під наркотою більше ніж 48 годин ?"
    },
    {
      "id": "drogueRegulier",
      "question": "Чи вживаєте Ви часто наркотики ?"
    },
    {
      "id": "plus4fois",
      "question": "Більше 4 разів на тиждень ?"
    },
    {
      "id": "acheteDrogue",
      "question": "Ви вже купляли наркотики ?"
    },
    {
      "id": "venduDrogue",
      "question": "Ви вже продавали наркотики ?"
    },
    {
      "id": "venduDrogueFinance",
      "question": "Чи продавали Ви вже наркотики, щоб самому ж хватало потім на дозу ?"
    },
    {
      "id": "badTrip",
      "question": "Ви вже мали 'bad trip' ?"
    },
    {
      "id": "initierDrogue",
      "question": "Чи Ви вже пропонували комусь наркотики ?"
    },
    {
      "id": "profiterQuelquun",
      "question": "Чи було таке, що Ви користувались кимось, коли він був напідпитку, під наркотиками чи був беззахисним ?"
    },
    {
      "id": "sextape",
      "question": "Ви коли-небудь знімали відео з сексом ?"
    },
    {
      "id": "rdvAmoureux",
      "question": "Чи бували Ви вже на побаченні ?"
    },
    {
      "id": "embrasseQuelquun",
      "question": "Ви вже когось цілували або ласкали ?"
    },
    {
      "id": "embrasserOrgasme",
      "question": "Ви вже доводили когось до оргазму своїми ласками або поцілунками ?"
    },
    {
      "id": "relationsSexuelles",
      "question": "У Вас вже був секс ?"
    },
    {
      "id": "payerSexe",
      "question": "Чи платили Ви колись за секс ?"
    },
    {
      "id": "sexeArgent",
      "question": "Чи спали Ви за гроші (секс мається на увазі) ?"
    },
    {
      "id": "impliqueFellation",
      "question": "Чи робили Вам або Ви комусь мінєт/кунілінгус ?"
    },
    {
      "id": "jusquaOrgasme",
      "question": "Аж до оргазму ?"
    },
    {
      "id": "rapportAnal",
      "question": "Чи був у Вас досвід анальний секс ?"
    },
    {
      "id": "jusquaOrgasme2",
      "question": "Аж до оргазму ?"
    },
    {
      "id": "fait69",
      "question": "Чи був у Вас досвід з позою 69 ?"
    },
    {
      "id": "ist",
      "question": "Чи хворіли Ви вже хворобами, які передають статеВим шляхом ?"
    },
    {
      "id": "sansContraception",
      "question": "Чи займались Ви вже сексом без конрацептивів(презерватив,протизаплідні таблетки) ?"
    },
    {
      "id": "avortement",
      "question": "Чи робили Ви або Ваш партнер аборт ?"
    },
    {
      "id": "plusDunePersonne",
      "question": "Чи був у Вас секс в трьох (груповуха) ?"
    },
    {
      "id": "plusDeuxPersonneSemaine",
      "question": "Чи був у Вас секс в трьох (груповуха) декілька раз на протязі короткого проміжку часу ?"
    },
    {
      "id": "sexeLieuPublic",
      "question": "Чи займалися Ви сексом у громадському місці ?"
    },
    {
      "id": "blessureSexe",
      "question": "Чи зазнавали Ви травм/порізів через секс ?"
    },
    {
      "id": "homo",
      "question": "Чи мали Ви досвід гомосексуальних cтатевих відносин ?"
    },
    {
      "id": "bdsm",
      "question": "Чи мали Ви вже сексуальний досвід садомазо або з бондажем ?"
    },
    {
      "id": "sextoys",
      "question": "Чи Використовували Ви секс іграшки ?"
    },
    {
      "id": "endormiSexe",
      "question": "Ви засинали або знепритомніювали під час сексу ?"
    },
    {
      "id": "perteVirginite",
      "question": "Чи Ви вже позбували когось цнотливості і якщо так, то скількох?"
    },
    {
      "id": "acheterSexShop",
      "question": "Ви вже куплялись щось в секс-шопі ?"
    },
    {
      "id": "sexeFamilial",
      "question": "Чи був у Вас секс з кимось з Вашої родини/родичем ?"
    },
    {
      "id": "hesiter",
      "question": "...Чи вагалися Ви відповісти на попереднє питання ?"
    },
    {
      "id": "zoophilieToussa",
      "question": "Вас приваблює або збуджує зоофілія, некрофілія чи педофілія ?"
    },
    {
      "id": "coupSoir",
      "question": "Чи був у Вас секс на одну ніч ?"
    },
    {
      "id": "combienCoupSoir",
      "question": "Скільки разів ?"
    },
    {
      "id": "sexeSansAurevoir",
      "question": "Чи був у Вас секс на одну ніч, і Ви потім йшли навіть не прощався з партнером ?"
    },
    {
      "id": "sexeMemeFamille",
      "question": "Чи спали Ви з кимось з однієї і тієї ж сім'ї(не обов'язково одночасно)?"
    },
    {
      "id": "sexeDejaRelation",
      "question": "Чи спали Ви вже з кимось хто був заручений або мав відносини з кимось іншим ?"
    },
    {
      "id": "sexeMarie",
      "question": "Чи були він/вона одружен(а)ий ?"
    },
    {
      "id": "sexeAmiPartenaire",
      "question": "Чи мали Ви вже секс з другом Вашого партнера ?"
    },
    {
      "id": "sexeDeuxFoisVieux",
      "question": "Чи мали Ви вже секс з кимось хто в два рази старший за Вас ?"
    },
    {
      "id": "bainMembreOppose",
      "question": "Чи приймали Ви ванну або душ з кимось з протилежної статті ?"
    },
    {
      "id": "lecherOeil",
      "question": "Ви лизали комусь око, вухо або пальці ніг ?"
    },
    {
      "id": "dejaMasturbation",
      "question": "Ви дрочили собі ?"
    },
    {
      "id": "masturbationTelephone",
      "question": "Ви вже дрочили спілкуючись по телефону(секс по телефону) ?"
    },
    {
      "id": "masturbationDeux",
      "question": "Дрочка з кимось ?"
    },
    {
      "id": "doucheDoree",
      "question": "Чи брали Ви участь у сквіртінгу ( Ви на когось або на Вас) ?"
    },
    {
      "id": "reveErotique",
      "question": "Чи маєте Ви свою еротичну/секс мрію ?"
    },
    {
      "id": "voyeur",
      "question": "Чи отримували Ви вже насолоду від нагляду за сексом інших ?"
    },
    {
      "id": "simulerOrgasme",
      "question": "Чи симулювали Ви оргазм ?"
    },
    {
      "id": "sortiSexe",
      "question": "Чи ходили Ви на побачення з кимось лише ради сексу ?"
    },
    {
      "id": "sexePari",
      "question": "Чи мали Ви вже секс тіки через те, щоб чисто виграти спор ?"
    },
    {
      "id": "sexeCoupDeVent",
      "question": "Чи мали Ви вже секс по бистрячку (в обід, на вулиці, раптово...) ?"
    },
    {
      "id": "sexePromotion",
      "question": "Чи мали Ви вже секс, щоб отримати роботу або підВищення ?"
    },
    {
      "id": "intimePublic",
      "question": "Чи виставляли Ви свої полові прелєсті на публіку ?"
    },
    {
      "id": "indelite",
      "question": "Чи зраджували Ви ?"
    },
    {
      "id": "sexePartenaireAmi",
      "question": "Чи мали Ви вже секс з партнером Вашого друга ?"
    },
    {
      "id": "sexeRegret",
      "question": "Чи мали Ви вже секс про який зараз жалієте ?"
    },
    {
      "id": "telephoneSexe",
      "question": "Чи телефонували або ж відповідали Ви на дзвінок під час сексу ?"
    },
    {
      "id": "teleSexe",
      "question": "Чи диВилися Ви телевізор під час сексу ?"
    },
    {
      "id": "dessinAnimeSexe",
      "question": "Чи задумувались Ви щодо статевого життя персонажів з аніме ?"
    },
    {
      "id": "ouSuisJe",
      "question": "Чи просиналися Ви в невідомому місці і питали когось де Ви є ?"
    },
    {
      "id": "ouSuisJeAvecQui",
      "question": "Чи просиналися Ви в невідомому місці і питали того, хто був збоку де Ви є і хто він такий ?"
    },
    {
      "id": "tatouer",
      "question": "Чи маєте Ви тату ?"
    },
    {
      "id": "travaillerCaritative",
      "question": "Ви коли-небудь працювали волонтером (на благодійність) ?"
    },
    {
      "id": "possedeBible",
      "question": "У Вас є Біблія ?"
    },
    {
      "id": "donCaritative",
      "question": "Ви жертвували гроші на благодійність ?"
    },
    {
      "id": "lieuCulte",
      "question": "Ви бували в церкві/мечеті/синагозі, окрім того, що були на весіллю або похороні ?"
    },
    {
      "id": "lieuCulteUneSemaine",
      "question": "Чи ходити Ви в церкву більше  ніж раз на тиждень ?"
    },
    {
      "id": "ptitfilou",
      "question": "Чи робили Ви щось з цього тесту спеціально аби покращити Вашу оцінку ?"
    }
  ],
  "results": {
    "global": {
      "you-are": "Ви",
      "your-score-is": "Ви набрали - ",
      "points": "балів",
      "reponses-positives": "Позитивні відповіді",
      "moyenne-line": "Оцінка може бути в діапазоні від {minScore} до {maxScore}, середній бал - {moyenne} (серед {testsNumber} пройдених тестів)"
    },
    "purity-levels": {
      "tres-pur": "ДУЖЕ ПРАВиЛЬНИЙ",
      "pur": "СВЯТОША",
      "plutot-pur": "ЧИСТИЙ",
      "impur": "ГРЯЗНІ",
      "salement-impur": "НУ ДУЖЕ ГРЯЗНІ",
      "dangereux-impur": "НЕБЕЗПЕЧНІ",
      "ignoble-impur": "МАНЬЯК"
    },
    "category": {
      "sex": "Секс",
      "alcohol": "Алкоголь",
      "drugs": "Ліки",
      "hygiene": "Гігієна",
      "moral": "Мораль",
      "positives": "Позитив"
    },
    "phrases": {
      "negatif": [
        "Не забудьте про Вашу сумку для покупок на базарі після цекрви в неділю...",
        "(Ваш бал є від'ємним - це означає, що Ви дуже правильні або дуже молоді, щоб пройти цей тест)"
      ],
      "10": [
        "Для Вас дві гарні сестрички це досі збочення!",
        "(Ви дуже правильний і походу з без досвіду, можливо, через свій вибір або незрілість ;-) )"
      ],
      "50": [
        "Перестаньте виживати і почніть жити!",
        "(Робіть те, що Ви хочете, головне ніколи ні про що не жаліти !)"
      ],
      "100": [
        "Дааа, Ви Використовуєте свою праву (або ліву) руку, щоб задовільнитись, як і всі інші !",
        "(Середній стандартний бал, нічого дивного!)"
      ],
      "150": [
        "Трошки вище середнього балу.Все, що можемо Вам сказати,що  Ви виходите тусити лише декілька раз на місяць !",
        "(Непоганий початок ! Вам не потрібно нікого соромитися !)"
      ],
      "200": [
        "Життя для Вас це просто величезний ігровий майданчик !",
        "(Ваше минуле було сповнене сюрпризів, і воно ще не закінчилось, і ще далеко до цього!)"
      ],
      "250": [
        "Ви живете на повну, без остраху про завтрашній день!",
        "(Оцінка, яка дає Вам право заявити про Вашу грязність :P)"
      ],
      "400": [
        "Оооо да....Приміряйте ці білу гамівну сорочку, і все буде добре! Люди в білих халатах подбають про тебе",
        "(Робіть в житті все, що Ви хочете! Головне - ніколи ні про що не жалійте!)"
      ],
      "max": [
        "Ну ти походу тварина, а не людина! Як би Ви могли лизати свох генаталії, то Ви б і з хати не виходили ",
        "(Йобаний маньяк!)"
      ]
    },
    "twitter": "J'ai fait {score} points au Test de Pureté #griffor ! Et toi ? :)"
  }
}
